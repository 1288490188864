<script setup lang="ts">
import type { ListedTokenDetails, UnlistedTokenDetails } from '#account/components/onboarding/OnboardingV2TokenDetails.vue'
import type { SelectCoin } from '#account/components/onboarding/OnboardingV2TokenSelect.vue'
import type { CoinData } from '~/server/routes/coingecko/coins/[id].get'

const onboarding = useOnboarding()
const token = ref<SelectCoin | null>(null)

watch(token, async (newVal) => {
  if (newVal) {
    onboarding.logger.info('token:watcher', { newVal })
    await fetchTokenDetails()
    onboarding.selectedToken = newVal
  }
  else {
    token.value = null
  }
})

async function fetchTokenDetails() {
  if (!token.value) {
    return
  }

  const res = await $fetch<CoinData>(`/coingecko/coins/${token.value.id}`)

  if (res) {
    if (res.asset_platform_id) {
      onboarding.baseLayerId = res.asset_platform_id
    }
    onboarding.tokenDetails = {
      coingeckoId: res.id,
      image: res.image.large,
      name: res.name,
      ticker: res.symbol,
      link: res.links.homepage[0],
      tgeDate: res.genesis_date,
    }
  }
}
</script>

<template>
  <OnboardingV2TokenSelect v-if="onboarding.tokenFindState !== 'token-not-listed'" v-model="token" />
  <OnboardingV2TokenDetails v-if="onboarding.tokenFindState === 'token-selected' || onboarding.tokenFindState === 'token-not-listed'" :key="token?.id" />
</template>
