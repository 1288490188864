import validate from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.4.1_@types+node@22.9.0_eslint@9.10.0_jiti@2.4.0__ioredis@5.4._ywb2t4re56kkldsmnvcp4jnop4/node_modules/nuxt/dist/pages/runtime/validate.js";
import is_45desktop_45global from "/opt/render/project/src/apps/ui/core/middleware/is-desktop.global.ts";
import nuxt_45studio_45global from "/opt/render/project/src/apps/ui/core/middleware/nuxt-studio.global.ts";
import redirects_45global from "/opt/render/project/src/apps/ui/core/middleware/redirects.global.ts";
import manifest_45route_45rule from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.4.1_@types+node@22.9.0_eslint@9.10.0_jiti@2.4.0__ioredis@5.4._ywb2t4re56kkldsmnvcp4jnop4/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  is_45desktop_45global,
  nuxt_45studio_45global,
  redirects_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/opt/render/project/src/apps/ui/core/middleware/auth.ts"),
  guest: () => import("/opt/render/project/src/apps/ui/core/middleware/guest.ts")
}