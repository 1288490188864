<script setup lang="ts">
import type { InjectedFormGroupValue } from '#ui/types'

import { CalendarDate, type DateValue } from '@internationalized/date'
import {
  type CalendarRootProps,
  DatePickerCalendar,
  DatePickerCell,
  DatePickerCellTrigger,
  DatePickerContent,
  DatePickerField,
  DatePickerGrid,
  DatePickerGridBody,
  DatePickerGridHead,
  DatePickerGridRow,
  DatePickerHeadCell,
  DatePickerHeader,
  DatePickerHeading,
  DatePickerInput,
  DatePickerNext,
  DatePickerPrev,
  DatePickerRoot,
  DatePickerTrigger,
} from 'radix-vue'

const props = defineProps(
  {
    modelValue: {
      type: Object as PropType<string | null>,
      required: true,
    },
    availableDays: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    useAvailableDays: {
      type: Boolean,
      default: false,
    },
  },
)
const emit = defineEmits(['update:modelValue'])
const WEEKDAYS = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa']

const dateObj = props.modelValue ? new Date(`${props.modelValue}T00:00:00`) : null
const model = ref<DateValue | null>(
  dateObj ? new CalendarDate(dateObj.getFullYear(), dateObj.getMonth() + 1, dateObj.getDate()) : null,
)

function isDateUnavailable(date: DateValue): CalendarRootProps['isDateUnavailable'] {
  if (!props.useAvailableDays) {
    return false
  }
  const dateString = `${date.year}-${date.month.toString().padStart(2, '0')}-${date.day.toString().padStart(2, '0')}`
  return !props.availableDays.includes(dateString)
}

function onUpdate(date: DateValue) {
  if (!date) {
    return
  }
  model.value = date
  emit('update:modelValue', `${date.year}-${date.month.toString().padStart(2, '0')}-${date.day.toString().padStart(2, '0')}`)
}

/**
 * Use the values from FormGroup to set the error state.
 * See: https://github.com/nuxt/ui/blob/dev/src/runtime/components/forms/FormGroup.vue
 */
const formGroup = inject<InjectedFormGroupValue>('form-group')
const showErrorState = computed(() => formGroup?.error.value)
</script>

<template>
  <DatePickerRoot
    data-ui="UiDatePicker"
    :model-value="(model as DateValue)"
    :is-date-unavailable="isDateUnavailable"
    v-bind="$attrs"
    @update:model-value="onUpdate($event as DateValue)"
  >
    <div
      class="w-full ring-1 rounded-lg ring-inset"
      :class="{ 'ring-forgd-red-600': showErrorState, 'ring-forgd-bgd-600': !showErrorState }"
    >
      <DatePickerTrigger v-if="$slots.default" class="w-full">
        <slot />
      </DatePickerTrigger>
      <DatePickerField
        v-else
        v-slot="{ segments }"
        class="flex select-none h-[50px] items-center justify-between rounded-lg text-center p-1 data-[invalid]:border-red-500"
      >
        <div class="flex items-center">
          <template
            v-for="item in segments"
            :key="item.part"
          >
            <DatePickerInput
              v-if="item.part === 'literal'"
              :part="item.part"
            >
              {{ item.value }}
            </DatePickerInput>
            <DatePickerInput
              v-else
              :part="item.part"
              class="rounded-md p-0.5 focus:outline-none focus:shadow-[0_0_0_2px] focus:shadow-black data-[placeholder]:text-green9 "
            >
              {{ item.value }}
            </DatePickerInput>
          </template>
        </div>

        <DatePickerTrigger class="rounded-md text-xl p-1 flex items-center">
          <UIcon name="i-heroicons-calendar" class="w-5 h-5" />
        </DatePickerTrigger>
      </DatePickerField>
    </div>

    <DatePickerContent
      :side-offset="8"
      align="start"
      class="bg-white rounded-md border border-border shadow-lg font-sans"
    >
      <DatePickerCalendar
        v-slot="{ weekDays, grid }"
        class="p-4"
      >
        <DatePickerHeader class="flex items-center justify-between pb-4 border-b border-b-forgd-gray-350">
          <DatePickerPrev
            class="inline-flex items-center cursor-pointer text-forgd-accent-300 justify-center bg-transparent w-8 h-8 active:scale-98 active:transition-all"
          >
            <UIcon name="i-heroicons-arrow-left" class="w-5 h-5" />
          </DatePickerPrev>

          <DatePickerHeading class="text-forgd-accent-300 font-semibold text-sm" />
          <DatePickerNext
            class="inline-flex items-center cursor-pointer text-forgd-accent-300 justify-center bg-transparent w-8 h-8 active:scale-98 active:transition-all"
          >
            <UIcon name="i-heroicons-arrow-right" class="w-5 h-5" />
          </DatePickerNext>
        </DatePickerHeader>
        <div
          class="flex flex-col space-y-4 pt-4 sm:flex-row sm:space-x-4 sm:space-y-0"
        >
          <DatePickerGrid
            v-for="month in grid"
            :key="month.value.toString()"
            class="w-full border-collapse select-none space-y-1"
          >
            <DatePickerGridHead>
              <DatePickerGridRow class="flex w-full justify-between">
                <DatePickerHeadCell
                  v-for="(day, index) in weekDays"
                  :key="day"
                  class="w-8 h-8 align-middle font-semibold text-xs text-forgd-gray-600"
                >
                  <div class="h-full flex justify-center items-center">
                    {{ WEEKDAYS[index] }}
                  </div>
                </DatePickerHeadCell>
              </DatePickerGridRow>
            </DatePickerGridHead>
            <DatePickerGridBody>
              <DatePickerGridRow
                v-for="(weekDates, index) in month.rows"
                :key="`weekDate-${index}`"
                class="flex w-full"
              >
                <DatePickerCell
                  v-for="weekDate in weekDates"
                  :key="weekDate.toString()"
                  :date="weekDate"
                >
                  <DatePickerCellTrigger
                    :day="weekDate"
                    :month="month.value"
                    class="relative flex items-center justify-center whitespace-nowrap rounded-full border border-transparent bg-transparent text-sm font-normal text-forgd-accent-300 w-10 h-10 outline-none data-[selected]:bg-forgd-accent-300 data-[disabled]:text-black/30 data-[selected]:text-white data-[unavailable]:pointer-events-none data-[unavailable]:cursor-not-allowed data-[unavailable]:text-black/30 data-[unavailable]:line-through"
                  />
                </DatePickerCell>
              </DatePickerGridRow>
            </DatePickerGridBody>
          </DatePickerGrid>
        </div>
      </DatePickerCalendar>
    </DatePickerContent>
  </DatePickerRoot>
</template>
