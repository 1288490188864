<script setup lang="ts">
import type { Teammate } from '#account/state/useOnboarding'
import AccountReadOnlyAlert from '#account/components/common/AccountReadOnlyAlert.vue'
import { MEMBERSHIP_MAX_ORGANIZATION_MEMBERS_LIMIT } from '#account/state/useOnboarding'
import { z } from 'zod'

const toast = useAppToast()
const auth = useAuth()
const onboarding = useOnboarding()

const teammateFormsState = ref<Partial<Teammate>[]>([{}])
const sendingType = ref<'none' | 'with-invitation' | 'without-invitation'>('none')

function removeTeammateForm(index: number) {
  teammateFormsState.value.splice(index, 1)
}

function updateTeammateForm(index: number, val: any) {
  teammateFormsState.value = teammateFormsState.value.map((item, i) => i === index ? toRaw(val) : item)
}

const schema = z.array(z.object({
  firstName: z.string().min(1),
  lastName: z.string().min(1),
  email: z.string().toLowerCase().email().refine(value => value !== auth.me?.email, {
    message: 'You cannot invite yourself',
  }),
}))

const canAddTeammate = computed(() => {
  return teammateFormsState.value.length < MEMBERSHIP_MAX_ORGANIZATION_MEMBERS_LIMIT
})

const canSubmit = computed(() => {
  const { success } = schema.safeParse(teammateFormsState.value)

  return success
})

async function onSubmit(skipInvitation = false) {
  sendingType.value = skipInvitation ? 'without-invitation' : 'with-invitation'
  const membersToInvite = skipInvitation ? [] : teammateFormsState.value.filter(m => m.firstName && m.lastName && m.email)
  try {
    const parsed = schema.parse(membersToInvite)
    onboarding.teammates = parsed
    await onboarding.complete()
    if (membersToInvite.length > 0) {
      toast.success({ title: 'Invitations sent successfully' })
    }
  }
  catch (e: unknown) {
    onboarding.logger.error(e)
    toast.error({ title: 'Failed to send invitations' })
  }
  finally {
    sendingType.value = 'none'
  }
}

function onError(error: unknown) {
  onboarding.logger.error(error)
}
</script>

<template>
  <UForm
    :state="teammateFormsState"
    :schema="schema"
    class="flex flex-col gap-2 w-[500px]"
    @submit="onSubmit(false)"
    @error="onError"
  >
    <div class="flex flex-col flex-grow gap-2">
      <UiButton :disabled="sendingType !== 'none'" :loading="sendingType === 'without-invitation'" variant="solid" size="lg" block @click="onSubmit(true)">
        Finalize Without Inviting Teammates
      </UiButton>

      <UDivider
        :label="`Or add up to ${MEMBERSHIP_MAX_ORGANIZATION_MEMBERS_LIMIT} Teammates`"
        :ui="{ label: 'text-sm text-gray-600 font-normal' }"
        class="my-10"
      />

      <template v-for="(state, key) in teammateFormsState" :key="key">
        <CardCreateTeammate
          :model-value="state"
          :can-delete="teammateFormsState.length > 1"
          :owner-email="auth.me!.email"
          @update:model-value="val => updateTeammateForm(key, val)"
          @delete="removeTeammateForm(key)"
        />
      </template>

      <AccountReadOnlyAlert />
    </div>

    <div class="flex gap-2">
      <UiButton
        :disabled="!canAddTeammate"
        icon="i-heroicons-plus"
        variant="outline"
        size="lg"
        trailing
        @click="teammateFormsState.push({})"
      >
        Add Another Teammate
      </UiButton>
      <OnboardingV2Continue :disabled="!canSubmit || sendingType !== 'none'" :loading="sendingType === 'with-invitation'">
        Send Invitations & Complete
      </OnboardingV2Continue>
    </div>
  </UForm>
</template>
