import type { UserMeResponse } from '@forgd/contract'
import { MemberStatusEnum } from '@forgd/supabase'

const dashboardPath = process.env.DASHBOARD_PATH || '/playbook'
const onboardingPath = process.env.ONBOARDING_PATH || '/onboarding'

/**
 * There are 6 scenarios:
 * 1. User has OWN memberStatus=active organization with no onboarded projects (no projects at all) → redirect to owner onboarding
 * 2. User has OWN memberStatus=active organization with an onboarded project → redirect to dashboard
 * 3. User has INVITED memberStatus=active organization with no onboarded projects → redirect to teammate onboarding with organizationId
 * 4. User has INVITED memberStatus=active organization with an onboarded project → redirect to dashboard
 * 5. There's route.query.organizationId → redirect to teammate onboarding with organizationId
 * 6. User has INVITED memberStatus=invited organization → redirect to teammate onboarding with organizationId
 */

export function inferOnboardingState(me: UserMeResponse) {
  // case when we need to redirect to teammate onboarding with organizationId (6)
  const invitedOrganization = me.organizations.find(
    org => org.memberStatus === MemberStatusEnum.Invited && org.ownerUserId !== me.id,
  )
  if (invitedOrganization) {
    return {
      scenario: 6,
      redirectTo: `${onboardingPath}/${invitedOrganization.id}/teammate`,
    }
  }

  // case when we need to redirect to teammate onboarding with organizationId (3)
  const activeInvitedOrganization = me.organizations.find(
    org => org.memberStatus === MemberStatusEnum.Active && org.ownerUserId !== me.id && !org.projects.length,
  )
  if (activeInvitedOrganization) {
    return {
      scenario: 3,
      redirectTo: `${onboardingPath}/${activeInvitedOrganization.id}/teammate`,
    }
  }

  // case when we need to redirect to dashboard (2, 4)
  const activeOrganizationWithOnboardedProject = me.organizations.find(
    org => org.memberStatus === MemberStatusEnum.Active && org.projects.length,
  )
  if (activeOrganizationWithOnboardedProject) {
    return {
      scenario: 2,
      redirectTo: dashboardPath,
    }
  }

  // case when we need to redirect to owner onboarding (1)
  const activeOwnOrganizationWithoutOnboardedProjects = me.organizations.find(
    org => org.memberStatus === MemberStatusEnum.Active && org.ownerUserId === me.id && (!org.projects.length || org.projects.some(p => !p.isOnboarded)),
  )
  if (activeOwnOrganizationWithoutOnboardedProjects) {
    return {
      scenario: 1,
      redirectTo: onboardingPath,
    }
  }

  throw new Error('No onboarding state found')
}
