<script setup lang="ts">
import OnboardingV2Preference from './OnboardingV2Preference.vue'

const onboarding = useOnboarding()

function handlePreferenceUpdate(preference: Preference, value: boolean) {
  if (value) {
    onboarding.selectedPreferences.push(preference)
  }
  else {
    onboarding.selectedPreferences = onboarding.selectedPreferences.filter(p => p.label !== preference.label)
  }
}

async function save() {
  onboarding.next()
}

const canSubmit = computed(
  () => Boolean(onboarding.selectedPreferences.length > 0),
)
</script>

<template>
  <div class="flex flex-wrap justify-center gap-4">
    <OnboardingV2Preference
      v-for="preference in onboarding.preferences"
      :key="preference.label"
      :label="preference.label"
      :model-value="onboarding.selectedPreferences.some(p => p.slug === preference.slug)"
      @update:model-value="handlePreferenceUpdate(preference, $event)"
    >
      <template #icon>
        <img :src="`/app/illustrations/onboarding/${preference.icon}`" alt="icon">
      </template>
      <template #label>
        {{ preference.label }}
      </template>
      <div v-html="preference.description" />
    </OnboardingV2Preference>
  </div>

  <OnboardingV2Continue class="w-[500px]" :disabled="!canSubmit" @click="save" />
</template>
