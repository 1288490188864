<script setup lang="ts">
import { Toggle } from 'radix-vue'

const emit = defineEmits(['optionClick'])

const toggleState = ref(false)

watch(toggleState, () => {
  emit('optionClick')
})
</script>

<template>
  <Toggle
    v-model:pressed="toggleState"
    as-child
  >
    <button
      class="h-[100px] w-[500px] rounded-lg border transition ease-in-out duration-300 font-semibold text-forgd-primary-900 flex items-center justify-center"
      :class="toggleState ? 'bg-forgd-primary-500 hover:bg-forgd-primary-500 border-forgd-primary-500 text-white' : 'bg-white hover:bg-forgd-neutral-600/30 border-forgd-neutral-700'"
    >
      <slot />
    </button>
  </Toggle>
</template>
