<script setup lang="ts">
import type { FormError, FormErrorEvent, FormEventType, FormSubmitEvent } from '#ui/types'
import type { BaseLayerTypeEnum, SectorTypeEnum } from '@forgd/supabase'
import type { z } from 'zod'
import UiDatePicker from '#core/components/form/UiDatePicker.vue'

import UiProjectImageUpload from '#core/components/form/UiProjectImageUpload.vue'
import { UpdateListedProjectBody, UpdateNotListedProjectBody } from '@forgd/contract'
import { type DateValue, getLocalTimeZone, today } from '@internationalized/date'

const onboarding = useOnboarding()

const schema = onboarding.tokenListed ? UpdateListedProjectBody : UpdateNotListedProjectBody
type TokenDetails = z.infer<typeof schema>
export type ListedTokenDetails = z.infer<typeof UpdateListedProjectBody>
export type UnlistedTokenDetails = z.infer<typeof UpdateNotListedProjectBody>

interface SectorOption {
  label: string
  value: string
  enum: keyof typeof SectorTypeEnum
}

const sectorOptions: SectorOption[] = [
  { label: 'DeFi', value: 'DeFi', enum: 'DeFi' },
  { label: 'GameFi', value: 'GameFi', enum: 'GameFi' },
  { label: 'Social', value: 'Social', enum: 'Social' },
  { label: 'DePIN', value: 'DePIN', enum: 'DePin' },
  { label: 'AI & Data', value: 'AI & Data', enum: 'AiAndData' },
  { label: 'Base Layer', value: 'Base Layer', enum: 'BaseLayer' },
  { label: 'Memecoin', value: 'Memecoin', enum: 'Memecoin' },
  { label: 'Wallet', value: 'Wallet', enum: 'Wallet' },
  { label: 'Gambling', value: 'Gambling', enum: 'Gambling' },
  { label: 'Oracle', value: 'Oracle', enum: 'Oracle' },
  { label: 'eCommerce', value: 'eCommerce', enum: 'ECommerce' },
]

interface BaseLayerOption {
  label: string
  value: string
  coingeckoId?: string
  enum: keyof typeof BaseLayerTypeEnum
}

const baseLayerOptions: Array<BaseLayerOption> = [
  { label: 'Bitcoin', value: 'Bitcoin', coingeckoId: 'bitcoin', enum: 'Bitcoin' },
  { label: 'Ethereum', value: 'Ethereum', coingeckoId: 'ethereum', enum: 'Ethereum' },
  { label: 'Solana', value: 'Solana', coingeckoId: 'solana', enum: 'Solana' },
  { label: 'Aptos', value: 'Aptos', coingeckoId: 'aptos', enum: 'Aptos' },
  { label: 'Arbitrum', value: 'Arbitrum', coingeckoId: 'arbitrum-one', enum: 'Arbitrum' },
  { label: 'Avalanche', value: 'Avalanche', coingeckoId: 'avalanche', enum: 'Avalanche' },
  { label: 'Injective', value: 'Injective', coingeckoId: 'injective', enum: 'Injective' },
  { label: 'Optimism', value: 'Optimism', coingeckoId: 'optimism', enum: 'Optimism' },
  { label: 'Polkadot', value: 'Polkadot', coingeckoId: 'polkadot', enum: 'Polkadot' },
  { label: 'Radix Ecosystem', value: 'Radix Ecosystem', coingeckoId: 'radix', enum: 'RadixEcosystem' },
  { label: 'Sei', value: 'Sei', coingeckoId: 'sei', enum: 'Sei' },
  { label: 'Sui', value: 'Sui', coingeckoId: 'sui', enum: 'Sui' },
  { label: 'TRON', value: 'TRON', coingeckoId: 'tron', enum: 'Tron' },
  { label: 'Other', value: 'Other', enum: 'Other' },
]

type State = 'initial' | 'error' | 'filled' | 'saved'

const state = ref<State>('initial')

const form = reactive<TokenDetails>({
  baseLayer: onboarding.tokenDetails?.baseLayer || [],
  coingeckoId: onboarding.tokenDetails?.coingeckoId || null,
  image: onboarding.tokenDetails?.image ?? '',
  link: onboarding.tokenDetails?.link ?? '',
  name: onboarding.tokenDetails?.name ?? '',
  sector: onboarding.tokenDetails?.sector ?? [],
  ticker: onboarding.tokenDetails?.ticker ?? '',
  tgeDate: onboarding.tokenDetails?.tgeDate ?? '',
})

function resetForm() {
  form.coingeckoId = onboarding.tokenDetails?.coingeckoId || null
  form.image = onboarding.tokenDetails?.image || ''
  form.link = onboarding.tokenDetails?.link || ''
  form.name = onboarding.tokenDetails?.name || ''
  form.ticker = onboarding.tokenDetails?.ticker || ''
  form.tgeDate = onboarding.tokenDetails?.tgeDate || ''
  if (onboarding.selectedBaseLayers) {
    form.baseLayer = onboarding.selectedBaseLayers.map(s => s.value as BaseLayerTypeEnum)
  }
  if (onboarding.selectedSectors) {
    form.sector = onboarding.selectedSectors.map(s => s.value as SectorTypeEnum)
  }
}

/**
 * Ensure that form is in sync with store
 */
onMounted(() => {
  onboarding.logger.info('token-details:onMounted', { tokenDetails: onboarding.tokenDetails })
  resetForm()
})

/**
 * Before the initial submit, we only validate on submit.
 * After the initial submit, we validate on blur as well.
 */
const validateOn = computed<FormEventType[]>(() => state.value === 'initial' ? ['submit'] : ['submit', 'blur'])

const contactError = ref(false)

function validate(state: any): FormError[] {
  const errors: FormError[] = []

  for (const key in state) {
    onboarding.logger.info('token-details:validate:key', { key, value: state[key] })
    if (key === 'coingeckoId' || key === 'image') {
      continue
    }
    if (!state[key]) {
      errors.push({ path: key, message: 'Required' })
      if (key === 'contact') {
        contactError.value = true
      }
    }
  }

  onboarding.logger.info('token-details:validate', { errors })
  return errors
}

/**
 * The Radix combobox isn't a Nuxt UI native thing, so
 * watch selectedBaseLayers and selectedSectors and manually
 * trigger the form validation when they change
 */
const formRef = ref<HTMLFormElement | null>(null)
async function validateForm() {
  try {
    await formRef.value?.validate()
  }
  catch {
    // avoid uncaught error in console
  }
}
onboarding.$subscribe((mutation, state) => {
  if (state.tokenDetails?.coingeckoId !== form.coingeckoId) {
    resetForm()
  }
  if (state.selectedBaseLayers) {
    form.baseLayer = state.selectedBaseLayers.map(s => s.value as BaseLayerTypeEnum)
    validateForm()
  }
  if (state.selectedSectors) {
    form.sector = state.selectedSectors.map(s => s.value as SectorTypeEnum)
    validateForm()
  }
})

async function onSubmit(_event: FormSubmitEvent<any>) {
  const result = schema.safeParse(form)
  if (result.success) {
    const { image, coingeckoId, ...formData } = result.data

    const body = onboarding.tokenFindState === 'token-not-found'
      ? {
          ...formData,
          coingeckoId: null,
          tokenListed: false,
          hasToken: true,
        }
      : {
          ...formData,
          image: image && image.length > 0 ? image : undefined,
          coingeckoId,
          tokenListed: !!coingeckoId,
          hasToken: true,
        }

    onboarding.tokenDetails = body
    onboarding.next()
  }
  state.value = 'error'
}

async function onError(_event: FormErrorEvent) {
  state.value = 'error'
}

const uiButton = {
  base: 'h-[50px]',
  rounded: 'rounded-lg',
  variant: {
    solid: 'shadow-none bg-forgd-primary-900 hover:bg-forgd-primary-900 disabled:bg-forgd-neutral-600/30 disabled:border disabled:border-forgd-neutral-600 disabled:text-forgd-neutral-600',
  },
}

const uiInput = {
  base: 'h-[50px]',
  rounded: 'rounded-lg',
  color: {
    white: {
      outline: 'shadow-none ring-forgd-bgd-600 disabled:bg-forgd-bgd-200',
    },
  },
  padding: {
    sm: 'px-4',
  },
  size: {
    sm: 'text-base',
  },
}

const uiSelect = {
  base: 'h-[50px]',
  color: {
    white: {
      outline: 'shadow-none ring-forgd-bgd-600 disabled:bg-forgd-bgd-200',
    },
  },
  padding: {
    sm: 'px-4',
  },
  rounded: 'rounded-lg',
}

const uFormNoError = {
  error: 'hidden',
}

function fixUrl() {
  if (form.link && form.link.length > 0 && !form.link.startsWith('https://')) {
    form.link = `https://${form.link}`
  }
}

const localDate = today(getLocalTimeZone())

function isDateDisabled(date: DateValue) {
  // post-TGE projects need to be able to select past dates
  if (onboarding.tokenListed) {
    return false
  }
  else {
    return date.compare(localDate) < 0
  }
}
</script>

<template>
  <UCard>
    <div class="p-5 space-y-5">
      <div class="font-semibold">
        Please provide some details related to your token.
        <div v-if="onboarding.tokenFindState !== 'token-not-listed'" class="text-xs text-forgd-gray-600 font-normal">
          All fields are mandatory
        </div>
      </div>

      <UForm
        ref="formRef"
        :validate="validate"
        :schema="schema"
        :state="form"
        class="space-y-5"
        :validate-on="validateOn"
        @submit="onSubmit"
        @error="onError"
      >
        <div v-if="onboarding.tokenDetails?.coingeckoId">
          <UFormGroup label="CoinGecko URL" name="coinGeckoURL">
            <UInput :model-value="`https://www.coingecko.com/en/coins/${onboarding.tokenDetails.coingeckoId}`" :ui="uiInput" disabled />
            <template #error />
          </UFormGroup>
        </div>
        <div class="grid grid-cols-2 gap-5">
          <UFormGroup label="Token name" name="name" :ui="uFormNoError">
            <UInput v-model="form.name" :ui="uiInput" />
          </UFormGroup>

          <UFormGroup label="Token ticker" name="ticker" :ui="uFormNoError">
            <UInput v-model="form.ticker!" :ui="uiInput" />
          </UFormGroup>

          <UFormGroup label="Sector" name="sector" :ui="uFormNoError">
            <OnboardingV2Combobox v-model="onboarding.selectedSectors" :options="sectorOptions" :ui="uiSelect" />
          </UFormGroup>

          <UFormGroup label="Token website" name="link" :ui="uFormNoError">
            <UInput v-model="form.link!" :ui="uiInput" @blur="fixUrl" />
          </UFormGroup>

          <UFormGroup label="Base layer" name="baseLayer" :ui="uFormNoError">
            <OnboardingV2Combobox v-model="onboarding.selectedBaseLayers" :options="baseLayerOptions" :ui="uiSelect" />
          </UFormGroup>

          <UFormGroup label="Date of TGE" name="tgeDate" :ui="uFormNoError">
            <UiDatePicker v-model="form.tgeDate!" :is-date-disabled="isDateDisabled" :use-available-days="false" />
          </UFormGroup>
        </div>

        <UFormGroup name="image">
          <UiProjectImageUpload :image="form.image!" @update:image="form.image = $event" />
        </UFormGroup>

        <UButton block :ui="uiButton" type="submit">
          Continue
          <template #trailing>
            <UIcon name="i-heroicons-arrow-right" class="w-4 h-4" />
          </template>
        </UButton>
      </UForm>
      
    </div>
  </UCard>
</template>
