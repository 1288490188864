import { default as aboutbiv2xFwzt2Meta } from "/opt/render/project/src/apps/ui/layers/site/pages/about.vue?macro=true";
import { default as _91_46_46_46slug_93kWD2UvLQc0Meta } from "/opt/render/project/src/apps/ui/layers/academy/pages/academy/[...slug].vue?macro=true";
import { default as indexqGERM17S37Meta } from "/opt/render/project/src/apps/ui/layers/tools-and-services/pages/auto-distribution/index.vue?macro=true";
import { default as indexVrPBGtAfMTMeta } from "/opt/render/project/src/apps/ui/layers/tools-and-services/pages/automated-market-making/index.vue?macro=true";
import { default as indexQyJcKfJ1WNMeta } from "/opt/render/project/src/apps/ui/layers/tools-and-services/pages/business-intelligence/index.vue?macro=true";
import { default as solanaclX1UOT3kYMeta } from "/opt/render/project/src/apps/ui/layers/tools-and-services/pages/business-intelligence/solana.vue?macro=true";
import { default as coming_45soonvPmlEIKwh0Meta } from "/opt/render/project/src/apps/ui/layers/site/pages/coming-soon.vue?macro=true";
import { default as indexu43JwQxHWtMeta } from "/opt/render/project/src/apps/ui/layers/auth/pages/confirm-email/index.vue?macro=true";
import { default as indexik768r9AL5Meta } from "/opt/render/project/src/apps/ui/layers/tools-and-services/pages/engage-a-market-maker-old/index.vue?macro=true";
import { default as indexgqYez6MYylMeta } from "/opt/render/project/src/apps/ui/layers/tools-and-services/pages/engage-a-market-maker/index.vue?macro=true";
import { default as quote_45detailsacEeJ4vPwNMeta } from "/opt/render/project/src/apps/ui/layers/tools-and-services/pages/engage-a-market-maker/quote-details.vue?macro=true";
import { default as received_45quotesWkoYTcKuqnMeta } from "/opt/render/project/src/apps/ui/layers/tools-and-services/pages/engage-a-market-maker/received-quotes.vue?macro=true";
import { default as received_45requotes4jFJsWJYDsMeta } from "/opt/render/project/src/apps/ui/layers/tools-and-services/pages/engage-a-market-maker/received-requotes.vue?macro=true";
import { default as request_45for_45quotationsviudEzBh3vMeta } from "/opt/render/project/src/apps/ui/layers/tools-and-services/pages/engage-a-market-maker/request-for-quotations.vue?macro=true";
import { default as exchange_45ratingsHE42HnxFAMMeta } from "/opt/render/project/src/apps/ui/layers/tools-and-services/pages/exchange-listing/exchange-ratings.vue?macro=true";
import { default as indexZOzdDdUHVRMeta } from "/opt/render/project/src/apps/ui/layers/tools-and-services/pages/exchange-listing/index.vue?macro=true";
import { default as listing_45feesfLkc4Zrfa1Meta } from "/opt/render/project/src/apps/ui/layers/tools-and-services/pages/exchange-listing/listing-fees.vue?macro=true";
import { default as listing_45questionnaireskvkS0uL9jHMeta } from "/opt/render/project/src/apps/ui/layers/tools-and-services/pages/exchange-listing/listing-questionnaires.vue?macro=true";
import { default as fees_45and_45pricingcG1DvnGN9vMeta } from "/opt/render/project/src/apps/ui/layers/account/pages/fees-and-pricing.vue?macro=true";
import { default as indexDL9sw43CUrMeta } from "/opt/render/project/src/apps/ui/layers/auth/pages/forgot-password/index.vue?macro=true";
import { default as indextEttFS3EZfMeta } from "/opt/render/project/src/apps/ui/layers/tools-and-services/pages/full-service-market-maker/index.vue?macro=true";
import { default as gitbookKvxLwth2BfMeta } from "/opt/render/project/src/apps/ui/layers/site/pages/gitbook.vue?macro=true";
import { default as indexMtXt8ADYjcMeta } from "/opt/render/project/src/apps/ui/layers/auth/pages/login/index.vue?macro=true";
import { default as indexL3oD6zAd95Meta } from "/opt/render/project/src/apps/ui/layers/auth/pages/login/verify/index.vue?macro=true";
import { default as indexrqv6mLEdgdMeta } from "/opt/render/project/src/apps/ui/layers/auth/pages/magic-link/index.vue?macro=true";
import { default as _91mode_936YbUJFjGG5Meta } from "/opt/render/project/src/apps/ui/layers/tools-and-services/pages/market-maker-monitoring/[mode].vue?macro=true";
import { default as dashboardajgK1tl9I4Meta } from "/opt/render/project/src/apps/ui/layers/tools-and-services/pages/market-maker-monitoring/dashboard.vue?macro=true";
import { default as indexJbAmMGcs0wMeta } from "/opt/render/project/src/apps/ui/layers/tools-and-services/pages/market-maker-monitoring/index.vue?macro=true";
import { default as start6HTqX8R3Z6Meta } from "/opt/render/project/src/apps/ui/layers/tools-and-services/pages/market-maker-monitoring/start.vue?macro=true";
import { default as mobile_45gateW6Wl7n5zQWMeta } from "/opt/render/project/src/apps/ui/layers/site/pages/mobile-gate.vue?macro=true";
import { default as teammate8iuFcYWbLwMeta } from "/opt/render/project/src/apps/ui/layers/account/pages/onboarding/[organizationId]/teammate.vue?macro=true";
import { default as indexbHJ1burVmVMeta } from "/opt/render/project/src/apps/ui/layers/account/pages/onboarding/index.vue?macro=true";
import { default as _91activity_93FCYwNgMA7DMeta } from "/opt/render/project/src/apps/ui/layers/tools-and-services/pages/partner-activity-lead/[activity].vue?macro=true";
import { default as _91partner_9357ZGAlGOMbMeta } from "/opt/render/project/src/apps/ui/layers/tools-and-services/pages/partner-schedule/[partner].vue?macro=true";
import { default as index5LV5o0DZefMeta } from "/opt/render/project/src/apps/ui/layers/auth/pages/password/new/index.vue?macro=true";
import { default as playbook9nc535d7e7Meta } from "/opt/render/project/src/apps/ui/layers/account/pages/playbook.vue?macro=true";
import { default as privacy_45policyOTXzDoiLrFMeta } from "/opt/render/project/src/apps/ui/layers/site/pages/privacy-policy.vue?macro=true";
import { default as indexsBLvu4FNItMeta } from "/opt/render/project/src/apps/ui/layers/auth/pages/register/index.vue?macro=true";
import { default as indexG2FAFvXy5UMeta } from "/opt/render/project/src/apps/ui/layers/auth/pages/reset-password/index.vue?macro=true";
import { default as settingsIBYkjtQvoXMeta } from "/opt/render/project/src/apps/ui/layers/account/pages/settings.vue?macro=true";
import { default as publicywPirCbU8PMeta } from "/opt/render/project/src/apps/ui/layers/token-designer/pages/share/[ticker]/[projectId]/public.vue?macro=true";
import { default as supportwzGaXL9cCaMeta } from "/opt/render/project/src/apps/ui/layers/account/pages/support.vue?macro=true";
import { default as terms_45of_45useZhQWa5jTpEMeta } from "/opt/render/project/src/apps/ui/layers/site/pages/terms-of-use.vue?macro=true";
import { default as modeling_45sell_45pressureA2nTjLygCJMeta } from "/opt/render/project/src/apps/ui/layers/token-designer/pages/token-designer/adjusting/modeling-sell-pressure.vue?macro=true";
import { default as modeling_45supply_45and_45demandcOAboSHD3vMeta } from "/opt/render/project/src/apps/ui/layers/token-designer/pages/token-designer/adjusting/modeling-supply-and-demand.vue?macro=true";
import { default as optimizing_45circulating_45supplyN4hanynrdCMeta } from "/opt/render/project/src/apps/ui/layers/token-designer/pages/token-designer/adjusting/optimizing-circulating-supply.vue?macro=true";
import { default as simulating_45post_45tge_45pops5vEvEegP2cMeta } from "/opt/render/project/src/apps/ui/layers/token-designer/pages/token-designer/adjusting/simulating-post-tge-pops.vue?macro=true";
import { default as simulating_45price_45discoveryurGkp43p6BMeta } from "/opt/render/project/src/apps/ui/layers/token-designer/pages/token-designer/adjusting/simulating-price-discovery.vue?macro=true";
import { default as indexaBtn33Gle9Meta } from "/opt/render/project/src/apps/ui/layers/token-designer/pages/token-designer/index.vue?macro=true";
import { default as demand_45driversASkVcYZWV0Meta } from "/opt/render/project/src/apps/ui/layers/token-designer/pages/token-designer/modeling/demand-drivers.vue?macro=true";
import { default as estimating_45demand5QNWfSA49mMeta } from "/opt/render/project/src/apps/ui/layers/token-designer/pages/token-designer/modeling/estimating-demand.vue?macro=true";
import { default as token_45distribution_45scheduleDKwalkEa1YMeta } from "/opt/render/project/src/apps/ui/layers/token-designer/pages/token-designer/modeling/token-distribution-schedule.vue?macro=true";
import { default as token_45emission_45scheduleC4tFTetmaQMeta } from "/opt/render/project/src/apps/ui/layers/token-designer/pages/token-designer/modeling/token-emission-schedule.vue?macro=true";
import { default as basic_45informationlUMahR1eKlMeta } from "/opt/render/project/src/apps/ui/layers/token-designer/pages/token-designer/token-profile/basic-information.vue?macro=true";
import { default as business_45modelxHW5vPs6b1Meta } from "/opt/render/project/src/apps/ui/layers/token-designer/pages/token-designer/token-profile/business-model.vue?macro=true";
import { default as mission_45visionhyP8tB43zYMeta } from "/opt/render/project/src/apps/ui/layers/token-designer/pages/token-designer/token-profile/mission-vision.vue?macro=true";
import { default as problem_45and_45solutionzMmJ7jxMVjMeta } from "/opt/render/project/src/apps/ui/layers/token-designer/pages/token-designer/token-profile/problem-and-solution.vue?macro=true";
import { default as product_45market_45fitKmPmji1IspMeta } from "/opt/render/project/src/apps/ui/layers/token-designer/pages/token-designer/token-profile/product-market-fit.vue?macro=true";
import { default as value_45captureafsCE8IPf1Meta } from "/opt/render/project/src/apps/ui/layers/token-designer/pages/token-designer/token-profile/value-capture.vue?macro=true";
import { default as value_45creationnqKWZH8uKsMeta } from "/opt/render/project/src/apps/ui/layers/token-designer/pages/token-designer/token-profile/value-creation.vue?macro=true";
import { default as fdv_45maximum_45token_45supplyhP8ge5J5rgMeta } from "/opt/render/project/src/apps/ui/layers/token-designer/pages/token-designer/valuation-documenting/fdv-maximum-token-supply.vue?macro=true";
import { default as sanitizing_45tokenomicsD4w2tsPzS2Meta } from "/opt/render/project/src/apps/ui/layers/token-designer/pages/token-designer/valuation-documenting/sanitizing-tokenomics.vue?macro=true";
import { default as token_45settingsHYFiVhKrkjMeta } from "/opt/render/project/src/apps/ui/layers/account/pages/token-settings.vue?macro=true";
import { default as indexABpPLza0lUMeta } from "/opt/render/project/src/apps/ui/layers/token-designer/pages/token-unlocks/index.vue?macro=true";
import { default as indexaB6DKjLij0Meta } from "/opt/render/project/src/apps/ui/layers/token-designer/pages/tokenomics/index.vue?macro=true";
import { default as toolsXFQsJlBnrxMeta } from "/opt/render/project/src/apps/ui/layers/account/pages/tools.vue?macro=true";
import { default as authorizationuE0WElZm72Meta } from "/opt/render/project/src/apps/ui/layers/account/pages/v2/authorization.vue?macro=true";
import { default as _91code_93mHhMlMfzfJMeta } from "/opt/render/project/src/apps/ui/layers/account/pages/v2/invitation/[code].vue?macro=true";
import { default as indexQcSJ4C888iMeta } from "/opt/render/project/src/apps/ui/layers/auth/pages/verify-email/index.vue?macro=true";
import { default as successotyTzKhlzZMeta } from "/opt/render/project/src/apps/ui/layers/auth/pages/waitlist/success.vue?macro=true";
import { default as what_45is_45forgdS1As6SDekEMeta } from "/opt/render/project/src/apps/ui/layers/account/pages/what-is-forgd.vue?macro=true";
import { default as component_45stubLnXrD7iLDLMeta } from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.4.1_@types+node@22.9.0_eslint@9.10.0_jiti@2.4.0__ioredis@5.4._ywb2t4re56kkldsmnvcp4jnop4/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubLnXrD7iLDL } from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.4.1_@types+node@22.9.0_eslint@9.10.0_jiti@2.4.0__ioredis@5.4._ywb2t4re56kkldsmnvcp4jnop4/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "about",
    path: "/about",
    component: () => import("/opt/render/project/src/apps/ui/layers/site/pages/about.vue")
  },
  {
    name: "academy-slug",
    path: "/academy/:slug(.*)*",
    meta: _91_46_46_46slug_93kWD2UvLQc0Meta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/academy/pages/academy/[...slug].vue")
  },
  {
    name: "auto-distribution",
    path: "/auto-distribution",
    meta: indexqGERM17S37Meta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/tools-and-services/pages/auto-distribution/index.vue")
  },
  {
    name: "automated-market-making",
    path: "/automated-market-making",
    meta: indexVrPBGtAfMTMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/tools-and-services/pages/automated-market-making/index.vue")
  },
  {
    name: "business-intelligence",
    path: "/business-intelligence",
    meta: indexQyJcKfJ1WNMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/tools-and-services/pages/business-intelligence/index.vue")
  },
  {
    name: "business-intelligence-solana",
    path: "/business-intelligence/solana",
    meta: solanaclX1UOT3kYMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/tools-and-services/pages/business-intelligence/solana.vue")
  },
  {
    name: "coming-soon",
    path: "/coming-soon",
    meta: coming_45soonvPmlEIKwh0Meta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/site/pages/coming-soon.vue")
  },
  {
    name: "confirm-email",
    path: "/confirm-email",
    meta: indexu43JwQxHWtMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/auth/pages/confirm-email/index.vue")
  },
  {
    name: "engage-a-market-maker-old",
    path: "/engage-a-market-maker-old",
    meta: indexik768r9AL5Meta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/tools-and-services/pages/engage-a-market-maker-old/index.vue")
  },
  {
    name: "engage-a-market-maker",
    path: "/engage-a-market-maker",
    meta: indexgqYez6MYylMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/tools-and-services/pages/engage-a-market-maker/index.vue")
  },
  {
    name: "engage-a-market-maker-quote-details",
    path: "/engage-a-market-maker/quote-details",
    meta: quote_45detailsacEeJ4vPwNMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/tools-and-services/pages/engage-a-market-maker/quote-details.vue")
  },
  {
    name: "engage-a-market-maker-received-quotes",
    path: "/engage-a-market-maker/received-quotes",
    meta: received_45quotesWkoYTcKuqnMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/tools-and-services/pages/engage-a-market-maker/received-quotes.vue")
  },
  {
    name: "engage-a-market-maker-received-requotes",
    path: "/engage-a-market-maker/received-requotes",
    meta: received_45requotes4jFJsWJYDsMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/tools-and-services/pages/engage-a-market-maker/received-requotes.vue")
  },
  {
    name: "engage-a-market-maker-request-for-quotations",
    path: "/engage-a-market-maker/request-for-quotations",
    meta: request_45for_45quotationsviudEzBh3vMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/tools-and-services/pages/engage-a-market-maker/request-for-quotations.vue")
  },
  {
    name: "exchange-listing-exchange-ratings",
    path: "/exchange-listing/exchange-ratings",
    meta: exchange_45ratingsHE42HnxFAMMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/tools-and-services/pages/exchange-listing/exchange-ratings.vue")
  },
  {
    name: "exchange-listing",
    path: "/exchange-listing",
    meta: indexZOzdDdUHVRMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/tools-and-services/pages/exchange-listing/index.vue")
  },
  {
    name: "exchange-listing-listing-fees",
    path: "/exchange-listing/listing-fees",
    meta: listing_45feesfLkc4Zrfa1Meta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/tools-and-services/pages/exchange-listing/listing-fees.vue")
  },
  {
    name: "exchange-listing-listing-questionnaires",
    path: "/exchange-listing/listing-questionnaires",
    meta: listing_45questionnaireskvkS0uL9jHMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/tools-and-services/pages/exchange-listing/listing-questionnaires.vue")
  },
  {
    name: "fees-and-pricing",
    path: "/fees-and-pricing",
    meta: fees_45and_45pricingcG1DvnGN9vMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/account/pages/fees-and-pricing.vue")
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    meta: indexDL9sw43CUrMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/auth/pages/forgot-password/index.vue")
  },
  {
    name: "full-service-market-maker",
    path: "/full-service-market-maker",
    meta: indextEttFS3EZfMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/tools-and-services/pages/full-service-market-maker/index.vue")
  },
  {
    name: "gitbook",
    path: "/gitbook",
    component: () => import("/opt/render/project/src/apps/ui/layers/site/pages/gitbook.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: indexMtXt8ADYjcMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/auth/pages/login/index.vue")
  },
  {
    name: "login-verify",
    path: "/login/verify",
    meta: indexL3oD6zAd95Meta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/auth/pages/login/verify/index.vue")
  },
  {
    name: "magic-link",
    path: "/magic-link",
    meta: indexrqv6mLEdgdMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/auth/pages/magic-link/index.vue")
  },
  {
    name: "market-maker-monitoring-mode",
    path: "/market-maker-monitoring/:mode()",
    meta: _91mode_936YbUJFjGG5Meta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/tools-and-services/pages/market-maker-monitoring/[mode].vue")
  },
  {
    name: "market-maker-monitoring-dashboard",
    path: "/market-maker-monitoring/dashboard",
    meta: dashboardajgK1tl9I4Meta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/tools-and-services/pages/market-maker-monitoring/dashboard.vue")
  },
  {
    name: "market-maker-monitoring",
    path: "/market-maker-monitoring",
    meta: indexJbAmMGcs0wMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/tools-and-services/pages/market-maker-monitoring/index.vue")
  },
  {
    name: "market-maker-monitoring-start",
    path: "/market-maker-monitoring/start",
    meta: start6HTqX8R3Z6Meta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/tools-and-services/pages/market-maker-monitoring/start.vue")
  },
  {
    name: "mobile-gate",
    path: "/mobile-gate",
    meta: mobile_45gateW6Wl7n5zQWMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/site/pages/mobile-gate.vue")
  },
  {
    name: "onboarding-organizationId-teammate",
    path: "/onboarding/:organizationId()/teammate",
    meta: teammate8iuFcYWbLwMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/account/pages/onboarding/[organizationId]/teammate.vue")
  },
  {
    name: "onboarding",
    path: "/onboarding",
    meta: indexbHJ1burVmVMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/account/pages/onboarding/index.vue")
  },
  {
    name: "partner-activity-lead-activity",
    path: "/partner-activity-lead/:activity()",
    meta: _91activity_93FCYwNgMA7DMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/tools-and-services/pages/partner-activity-lead/[activity].vue")
  },
  {
    name: "partner-schedule-partner",
    path: "/partner-schedule/:partner()",
    meta: _91partner_9357ZGAlGOMbMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/tools-and-services/pages/partner-schedule/[partner].vue")
  },
  {
    name: "password-new",
    path: "/password/new",
    meta: index5LV5o0DZefMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/auth/pages/password/new/index.vue")
  },
  {
    name: "playbook",
    path: "/playbook",
    meta: playbook9nc535d7e7Meta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/account/pages/playbook.vue")
  },
  {
    name: "privacy-policy",
    path: "/privacy-policy",
    meta: privacy_45policyOTXzDoiLrFMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/site/pages/privacy-policy.vue")
  },
  {
    name: "register",
    path: "/register",
    meta: indexsBLvu4FNItMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/auth/pages/register/index.vue")
  },
  {
    name: "reset-password",
    path: "/reset-password",
    meta: indexG2FAFvXy5UMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/auth/pages/reset-password/index.vue")
  },
  {
    name: "settings",
    path: "/settings",
    meta: settingsIBYkjtQvoXMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/account/pages/settings.vue")
  },
  {
    name: "share-ticker-projectId-public",
    path: "/share/:ticker()/:projectId()/public",
    meta: publicywPirCbU8PMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/token-designer/pages/share/[ticker]/[projectId]/public.vue")
  },
  {
    name: "support",
    path: "/support",
    meta: supportwzGaXL9cCaMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/account/pages/support.vue")
  },
  {
    name: "terms-of-use",
    path: "/terms-of-use",
    meta: terms_45of_45useZhQWa5jTpEMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/site/pages/terms-of-use.vue")
  },
  {
    name: "token-designer-adjusting-modeling-sell-pressure",
    path: "/token-designer/adjusting/modeling-sell-pressure",
    meta: modeling_45sell_45pressureA2nTjLygCJMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/token-designer/pages/token-designer/adjusting/modeling-sell-pressure.vue")
  },
  {
    name: "token-designer-adjusting-modeling-supply-and-demand",
    path: "/token-designer/adjusting/modeling-supply-and-demand",
    meta: modeling_45supply_45and_45demandcOAboSHD3vMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/token-designer/pages/token-designer/adjusting/modeling-supply-and-demand.vue")
  },
  {
    name: "token-designer-adjusting-optimizing-circulating-supply",
    path: "/token-designer/adjusting/optimizing-circulating-supply",
    meta: optimizing_45circulating_45supplyN4hanynrdCMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/token-designer/pages/token-designer/adjusting/optimizing-circulating-supply.vue")
  },
  {
    name: "token-designer-adjusting-simulating-post-tge-pops",
    path: "/token-designer/adjusting/simulating-post-tge-pops",
    meta: simulating_45post_45tge_45pops5vEvEegP2cMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/token-designer/pages/token-designer/adjusting/simulating-post-tge-pops.vue")
  },
  {
    name: "token-designer-adjusting-simulating-price-discovery",
    path: "/token-designer/adjusting/simulating-price-discovery",
    meta: simulating_45price_45discoveryurGkp43p6BMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/token-designer/pages/token-designer/adjusting/simulating-price-discovery.vue")
  },
  {
    name: "token-designer",
    path: "/token-designer",
    meta: indexaBtn33Gle9Meta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/token-designer/pages/token-designer/index.vue")
  },
  {
    name: "token-designer-modeling-demand-drivers",
    path: "/token-designer/modeling/demand-drivers",
    meta: demand_45driversASkVcYZWV0Meta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/token-designer/pages/token-designer/modeling/demand-drivers.vue")
  },
  {
    name: "token-designer-modeling-estimating-demand",
    path: "/token-designer/modeling/estimating-demand",
    meta: estimating_45demand5QNWfSA49mMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/token-designer/pages/token-designer/modeling/estimating-demand.vue")
  },
  {
    name: "token-designer-modeling-token-distribution-schedule",
    path: "/token-designer/modeling/token-distribution-schedule",
    meta: token_45distribution_45scheduleDKwalkEa1YMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/token-designer/pages/token-designer/modeling/token-distribution-schedule.vue")
  },
  {
    name: "token-designer-modeling-token-emission-schedule",
    path: "/token-designer/modeling/token-emission-schedule",
    meta: token_45emission_45scheduleC4tFTetmaQMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/token-designer/pages/token-designer/modeling/token-emission-schedule.vue")
  },
  {
    name: "token-designer-token-profile-basic-information",
    path: "/token-designer/token-profile/basic-information",
    meta: basic_45informationlUMahR1eKlMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/token-designer/pages/token-designer/token-profile/basic-information.vue")
  },
  {
    name: "token-designer-token-profile-business-model",
    path: "/token-designer/token-profile/business-model",
    meta: business_45modelxHW5vPs6b1Meta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/token-designer/pages/token-designer/token-profile/business-model.vue")
  },
  {
    name: "token-designer-token-profile-mission-vision",
    path: "/token-designer/token-profile/mission-vision",
    meta: mission_45visionhyP8tB43zYMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/token-designer/pages/token-designer/token-profile/mission-vision.vue")
  },
  {
    name: "token-designer-token-profile-problem-and-solution",
    path: "/token-designer/token-profile/problem-and-solution",
    meta: problem_45and_45solutionzMmJ7jxMVjMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/token-designer/pages/token-designer/token-profile/problem-and-solution.vue")
  },
  {
    name: "token-designer-token-profile-product-market-fit",
    path: "/token-designer/token-profile/product-market-fit",
    meta: product_45market_45fitKmPmji1IspMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/token-designer/pages/token-designer/token-profile/product-market-fit.vue")
  },
  {
    name: "token-designer-token-profile-value-capture",
    path: "/token-designer/token-profile/value-capture",
    meta: value_45captureafsCE8IPf1Meta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/token-designer/pages/token-designer/token-profile/value-capture.vue")
  },
  {
    name: "token-designer-token-profile-value-creation",
    path: "/token-designer/token-profile/value-creation",
    meta: value_45creationnqKWZH8uKsMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/token-designer/pages/token-designer/token-profile/value-creation.vue")
  },
  {
    name: "token-designer-valuation-documenting-fdv-maximum-token-supply",
    path: "/token-designer/valuation-documenting/fdv-maximum-token-supply",
    meta: fdv_45maximum_45token_45supplyhP8ge5J5rgMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/token-designer/pages/token-designer/valuation-documenting/fdv-maximum-token-supply.vue")
  },
  {
    name: "token-designer-valuation-documenting-sanitizing-tokenomics",
    path: "/token-designer/valuation-documenting/sanitizing-tokenomics",
    meta: sanitizing_45tokenomicsD4w2tsPzS2Meta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/token-designer/pages/token-designer/valuation-documenting/sanitizing-tokenomics.vue")
  },
  {
    name: "token-settings",
    path: "/token-settings",
    meta: token_45settingsHYFiVhKrkjMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/account/pages/token-settings.vue")
  },
  {
    name: "token-unlocks",
    path: "/token-unlocks",
    meta: indexABpPLza0lUMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/token-designer/pages/token-unlocks/index.vue")
  },
  {
    name: "tokenomics",
    path: "/tokenomics",
    meta: indexaB6DKjLij0Meta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/token-designer/pages/tokenomics/index.vue")
  },
  {
    name: "tools",
    path: "/tools",
    meta: toolsXFQsJlBnrxMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/account/pages/tools.vue")
  },
  {
    name: "v2-authorization",
    path: "/v2/authorization",
    meta: authorizationuE0WElZm72Meta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/account/pages/v2/authorization.vue")
  },
  {
    name: "v2-invitation-code",
    path: "/v2/invitation/:code()",
    meta: _91code_93mHhMlMfzfJMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/account/pages/v2/invitation/[code].vue")
  },
  {
    name: "verify-email",
    path: "/verify-email",
    meta: indexQcSJ4C888iMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/auth/pages/verify-email/index.vue")
  },
  {
    name: "waitlist-success",
    path: "/waitlist/success",
    meta: successotyTzKhlzZMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/auth/pages/waitlist/success.vue")
  },
  {
    name: "what-is-forgd",
    path: "/what-is-forgd",
    meta: what_45is_45forgdS1As6SDekEMeta || {},
    component: () => import("/opt/render/project/src/apps/ui/layers/account/pages/what-is-forgd.vue")
  },
  {
    name: component_45stubLnXrD7iLDLMeta?.name,
    path: "/",
    component: component_45stubLnXrD7iLDL
  }
]