import type { Option } from '#account/components/onboarding/OnboardingV2ButtonGroup.vue'
import type { ListedTokenDetails, UnlistedTokenDetails } from '#account/components/onboarding/OnboardingV2TokenDetails.vue'
import type { SelectCoin } from '#account/components/onboarding/OnboardingV2TokenSelect.vue'
import type { ComboboxOption } from '#core/components/form/UiCombobox.vue'
import type { MemberContactType, OwnerDetailsBody, UpdateListedProjectBody, UpdateNotListedProjectBody } from '@forgd/contract'
import type { LeadSourceTypeEnum } from '@forgd/supabase'
import type { z } from 'zod'
import ButtonGroup from '#account/components/onboarding/OnboardingV2ButtonGroup.vue'
import Preferences from '#account/components/onboarding/OnboardingV2Preferences.vue'
import TeammateDetails from '#account/components/onboarding/OnboardingV2TeammateDetails.vue'

import Teammates from '#account/components/onboarding/OnboardingV2Teammates.vue'
import Token from '#account/components/onboarding/OnboardingV2Token.vue'
import UserDetails from '#account/components/onboarding/OnboardingV2UserDetails.vue'
import Processing from '#core/components/form/UiProcessing.vue'
import { useUrlSearchParams } from '@vueuse/core'

import { createConsola } from 'consola'
import { acceptHMRUpdate, defineStore } from 'pinia'

type OwnerDetails = z.infer<typeof OwnerDetailsBody>

export const MEMBERSHIP_MAX_ORGANIZATION_MEMBERS_LIMIT = 20 // todo: read from env

interface OnboardingStep {
  slug: string
  apiName?: string
  heading: string | ComputedRef<string>
  subheading?: string
  component: Component
  bind?: any
  onSave?: () => void
  condition?: (state: OnboardingState) => boolean
}

interface OnboardingState {
  isResearch: boolean
}

export type TokenFindState = 'initial' | 'token-not-found' | 'token-selected' | 'token-not-listed'

export interface Preference {
  label: string
  icon: string
  slug: string
  description: string
}

export interface Teammate {
  firstName: string
  lastName: string
  email: string
  position?: string
}

const listedTokenPreferences: Array<Preference> = [
  {
    label: 'Analysis & Insights',
    icon: 'analysis-insights.svg',
    slug: 'analysis_and_insights',
    description: `
      <p>Track market dynamics, token metrics, and trading KPIs to maintain a competitive edge.</p>

      <ul>
        <li>Monitor your Market Maker</li>
        <li>Token Unlocks & Sell Pressure</li>
        <li>Volume, Depth, Spreads, & Price</li>
      </ul>
    `,
  },
  {
    label: 'Strategic Partnerships',
    icon: 'strategic-partnerships.svg',
    slug: 'strategic_partnerships',
    description: `
      <p>Expand your network with key industry players to bolster listing success and amplify your market presence.</p>

      <ul>
        <li>Engage Market Makers</li>
        <li>List on Exchanges</li>
        <li>Marketing & KOL Support</li>
      </ul>
    `,
  },
  {
    label: 'Performance Optimization',
    icon: 'performance-optimization.svg',
    slug: 'performance_optimization',
    description: `
      <p>Fine-tune your tokenomics, enhance smart contracts, and maximize token utility for sustained growth.</p>

      <ul>
        <li>Tokenomics Revision</li>
        <li>Self-service Market Making</li>
        <li>Smart Contract & Protocol Development</li>
        <li>Airdrops & Token Streaming</li>
      </ul>
    `,
  },
  {
    label: 'Finance & Fundraising',
    icon: 'finance-fundraising.svg',
    slug: 'finance_and_fundraising',
    description: `
      <p>Analyze financial health and secure capital to fuel your project’s expansion and innovation.</p>

      <ul>
        <li>Cashflow Analysis</li>
        <li>Growth Capital</li>
      </ul>
    `,
  },
  {
    label: 'Legal & Compliance',
    icon: 'legal-compliance.svg',
    slug: 'legal_compliance',
    description: `
      <p>Navigate regulatory landscapes with expert legal guidance tailored to your project’s needs.</p>

      <ul>
        <li>External General Counsel</li>
        <li>Legal Opinion on Token</li>
      </ul>
    `,
  },
]

const projectOwnerPreferences: Array<Preference> = [
  {
    label: 'Tokenomics & Protocol',
    icon: 'tokenomics-protocol.svg',
    slug: 'tokenomics_and_protocol',
    description: `
      <p>Design & develop your protocol, mint tokens, and automate distribution of your cap table.</p>

      <ul>
        <li>Design Tokenomics</li>
        <li>Smart Contract Development</li>
        <li>Token Creation & Mint</li>
        <li>Airdrops & Token Streaming</li>
        <li>Token Custody</li>
      </ul>
    `,
  },
  {
    label: 'Go-to-Market Partnerships',
    icon: 'go-to-market-partnerships.svg',
    slug: 'go_to_market_partnerships',
    description: `
      <p>Align with market makers, exchanges, and KOLs to promote a strategic launch.</p>

      <ul>
        <li>Engage Market Makers</li>
        <li>List on Exchanges</li>
        <li>Marketing & KOL Support</li>
      </ul>
    `,
  },
  {
    label: 'Legal & Compliance',
    icon: 'legal-compliance.svg',
    slug: 'legal_compliance',
    description: `
      <p>Structure your foundation and secure a legal opinion regarding the regulatory status of your token.</p>

      <ul>
        <li>Entity formation & incorporation</li>
        <li>Engage external General Counsel</li>
        <li>Get a Legal Opinion on your Token</li>
      </ul>
    `,
  },
  {
    label: 'Finance & Fundraising',
    icon: 'finance-fundraising.svg',
    slug: 'finance_and_fundraising',
    description: `
      <p>Construct your budget & cashflow model. Prepare for token sales (private or public) or equity transaction.</p>

      <ul>
        <li>Conduct Cashflow Analysis</li>
        <li>Growth Capital</li>
        <li>Go-to-Market Budgeting</li>
        <li>Public Sale, Launchpad</li>
      </ul>
    `,
  },
]

const researcherPreferences: Array<Preference> = [
  {
    label: 'Tokenomics Research',
    slug: 'tokenomics_research',
    icon: 'tokenomics-research.svg',
    description: `Conduct research on tokenomics & protocol value flows. Interact with Forgd frameworks for designing emissions, demand drivers, and value accrual mechanisms.`,
  },
  {
    label: 'Market Making Research',
    slug: 'market_making_research',
    icon: 'market-making-research.svg',
    description: `Conduct research on market makers & liquidity provision. Learn best practices for engaging service providers with symbiotic relationships.`,
  },
  {
    label: 'Exchange Listing Research',
    slug: 'exchange_listing_research',
    icon: 'exchange-listing-research.svg',
    description: `Conduct research on exchanges. Assess performance of past listings, trading KPIs, fee structures, and more.`,
  },
]

const steps: Array<OnboardingStep> = [
  {
    slug: 'projectType',
    heading:
      'Are you part of a blockchain project that has a token or will it issue one in the future?',
    component: shallowRef(ButtonGroup),
    bind: {
      step: 'projectType',
      options: [
        { id: 1, value: 'hasToken', label: 'Yes' },
        { id: 2, value: 'isResearch', label: 'No. I want to use Forgd to conduct research.' },
      ],
    },
  },
  {
    slug: 'tokenListed',
    heading:
      'Is your token listed on centralized or decentralized exchanges?',
    component: shallowRef(ButtonGroup),
    bind: {
      step: 'tokenListed',
      options: [
        { id: 1, value: 'tokenListed', label: 'Yes. The token is listed' },
        { id: 2, value: 'tokenNotListed', label: 'No. The token is not listed yet' },
      ],
    },
    condition: (state: OnboardingState) => state.isResearch === false,
  },
  {
    slug: 'projectDetails',
    heading: 'Tell us a bit more about your project & token.',
    component: shallowRef(Token),
    condition: (state: OnboardingState) => state.isResearch === false,
  },
  {
    slug: 'areasOfInterest',
    heading: 'How do you plan to use Forgd?',
    subheading: 'Select at least one option. You can always change this later.',
    component: shallowRef(Preferences),
  },
  {
    slug: 'ownerDetails',
    heading: `Great! We are almost finished. Let's wrap up a few additional details.`,
    component: shallowRef(UserDetails),
  },
  {
    slug: 'memberInvitations',
    heading: `Invite teammates to collaborate`,
    subheading: 'Teammates will have “Read-only” access to your project. They can see all the available data but won’t be able to activate, edit or configure tools.',
    component: shallowRef(Teammates),
  },
  {
    slug: 'processing',
    heading: '',
    component: shallowRef(Processing),
    bind: {
      title: 'We are processing your account details',
      subtitle: 'This might take a few seconds',
    },
  },
]

export const useOnboarding = defineStore('onboarding', () => {
  const auth = useAuth()
  const urlParams = useUrlSearchParams()

  // 999 is verbose, -999 is silent, working around LogLevels import
  const logger = createConsola({
    level: (import.meta.dev || auth.me?.email.includes('@forgd.com')) ? +999 : -999,
    defaults: {
      tag: 'onboarding',
    },
  })

  /**
   * Current state
   */
  const baseLayerId = ref<string | null>(null)
  const currentStep = ref<OnboardingStep>(steps[0])
  const currentStepSlug = ref<string>(steps[0].slug)
  const ownerDetails = ref<OwnerDetails>()
  const selectedBaseLayers = ref<Array<ComboboxOption>>([])
  const selectedSectors = ref<Array<ComboboxOption>>([])
  const selectedPreferences = ref<Array<Preference>>([])
  const selectedPreferenceSlugs = ref<Array<string>>([])
  const selectedToken = ref<SelectCoin | null>(null)
  const listedTokenDetails = ref<Partial<ListedTokenDetails> | null>(null)
  const unlistedTokenDetails = ref<Partial<UnlistedTokenDetails> | null>(null)
  const tokenDetails = ref<ListedTokenDetails | UnlistedTokenDetails | null>(null)
  function isUpdateListedProjectBody(
    details: ListedTokenDetails | UnlistedTokenDetails | null,
  ): details is ListedTokenDetails {
    return tokenListed.value === true
  }
  const isComplete = ref(false)

  /**
   * These values are used by the API
   */
  const tokenListed = ref<boolean | null>(null)
  const isResearch = ref<boolean>(false)
  const isTeammate = ref<boolean | null>(null)
  const hasToken = computed(() => !isResearch.value)
  const projectId = ref<string | null>(auth.project?.id || null)
  const teammates = ref<Array<Teammate>>([])

  const showBackButton = computed(() => currentStepSlug.value !== 'projectType' && !isTeammate.value)

  const preferences = ref<Array<Preference>>([])

  const tokenFindState = ref<TokenFindState>('initial')

  function handleOptionClick(option: Option, step: string) {
    logger.info('handleOptionClick', { option, step })
    if (step === 'projectType') {
      if (option.value === 'hasToken') {
        isResearch.value = false
      }
      else {
        isResearch.value = true
        tokenListed.value = null
        preferences.value = researcherPreferences
      }
    }
    if (step === 'tokenListed') {
      if (option.value === 'tokenListed') {
        preferences.value = listedTokenPreferences
        selectedToken.value = null
        steps[2].heading = `Let's find your token.`
        tokenFindState.value = 'initial'
        tokenListed.value = true
      }
      else {
        preferences.value = projectOwnerPreferences
        selectedBaseLayers.value = []
        steps[2].heading = 'Tell us a bit more about your project & token.'
        tokenDetails.value = null
        tokenFindState.value = 'token-not-listed'
        tokenListed.value = false
      }
    }
    next()
  }

  async function complete() {
    if (!ownerDetails.value || !tokenDetails.value) {
      return
    }

    // initialize the project with isResearch
    try {
      const project = await $core('/onboarding/init-project', {
        method: 'POST',
        body: {
          isResearch: isResearch.value,
          organizationId: auth.organization!.id,
        },
      })
      logger.info('complete:init-project', {
        project,
      })
      projectId.value = project!.id
    }
    catch {
      throw createError({
        statusCode: 500,
        statusMessage: `We couldn't initialize your project.`,
      })
    }

    try {
      const owner = await $core('/onboarding/owner-details', {
        method: 'POST',
        body: {
          ...ownerDetails.value,
          contactType: ownerDetails.value?.contactType as MemberContactType,
          leadSourceType: ownerDetails.value?.leadSourceType as LeadSourceTypeEnum,
          projectId: projectId.value,
        },
      })
      logger.info('complete:owner-details', {
        owner,
      })
    }
    catch {
      throw createError({
        statusCode: 500,
        statusMessage: `We couldn't update your owner details.`,
      })
    }

    try {
      const projectDetails = await $core(isUpdateListedProjectBody(tokenDetails.value) ? '/onboarding/listed-project-details/{projectId}' : '/onboarding/not-listed-project-details/{projectId}', {
        path: {
          projectId: projectId.value,
        },
        method: 'POST',
        body: tokenDetails.value,
      })
      logger.info('complete:project-details', {
        projectDetails,
      })
    }
    catch {
      throw createError({
        statusCode: 500,
        statusMessage: `We couldn't update your project details.`,
      })
    }

    try {
      await $core('/onboarding/complete', {
        method: 'POST',
        body: {
          projectId: projectId.value,
          members: teammates.value,
        },
      })
    }
    catch {
      throw createError({
        statusCode: 500,
        statusMessage: `We couldn't complete your onboarding.`,
      })
    }
    isComplete.value = true
    await auth.refresh({ from: 'useOnboarding:complete' })
    await navigateTo(auth.dashboardPath)
  }

  watch(currentStep, async (newVal) => {
    if (newVal) {
      logger.info('store:currentStep:watcher', { slug: newVal.slug })
      await nextTick()
      currentStepSlug.value = newVal.slug
      urlParams.step = newVal.slug
    }
  })

  async function next() {
    logger.info('next', { currentStepSlug: currentStepSlug.value })
    const index = steps.findIndex(step => step.slug === currentStepSlug.value)
    logger.info('next', { index })
    const nextStep = steps.findIndex((step, i) => i > index && (step.condition ? step.condition({ isResearch: isResearch.value }) : true))
    logger.info('next', { nextStep })
    currentStep.value = steps[nextStep]
    currentStepSlug.value = currentStep.value.slug
  }

  function previous() {
    logger.info('previous', { currentStepSlug: currentStepSlug.value })
    const index = steps.findIndex(step => step.slug === currentStepSlug.value)
    logger.info('previous', { index })
    const previousStep = steps.findLastIndex((step, i) => i < index && (step.condition ? step.condition({ isResearch: isResearch.value }) : true))
    logger.info('previous', { previousStep })
    currentStep.value = steps[previousStep]
    currentStepSlug.value = currentStep.value.slug
  }

  const progress = computed(() => {
    const index = steps.findIndex(step => step.slug === currentStepSlug.value) + 1
    return index / steps.length * 100
  })

  async function initialize() {
    logger.info('initialize', {
      currentStepSlug: currentStepSlug.value,
      isResearch: isResearch.value,
      isComplete: isComplete.value,
    })
    if (isComplete.value) {
      await auth.redirect({ from: 'useOnboarding:initialize' })
    }
    projectId.value = auth.project?.id || null
    currentStep.value = steps.find(step => step.slug === currentStepSlug.value) || steps[0]
    currentStep.value.component = shallowRef(steps.find(step => step.slug === currentStepSlug.value)?.component)
    preferences.value = isResearch.value
      ? researcherPreferences
      : tokenListed.value
        ? listedTokenPreferences
        : projectOwnerPreferences
  }

  return {
    baseLayerId,
    currentStep,
    currentStepSlug,
    hasToken,
    isResearch,
    isTeammate,
    isComplete,
    logger,
    ownerDetails,
    progress,
    preferences,
    projectId,
    selectedBaseLayers,
    selectedSectors,
    selectedPreferences,
    selectedPreferenceSlugs,
    selectedToken,
    listedTokenDetails,
    unlistedTokenDetails,
    showBackButton,
    steps,
    tokenListed,
    tokenFindState,
    tokenDetails,
    teammates,
    handleOptionClick,
    initialize,
    next,
    previous,
    complete,
  }
}, {
  persist: {
    omit: ['currentStep', 'logger', 'preferences'],
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useOnboarding, import.meta.hot))
}
