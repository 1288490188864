import unhead_CKEdhMrsU0 from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.4.1_@types+node@22.9.0_eslint@9.10.0_jiti@2.4.0__ioredis@5.4._ywb2t4re56kkldsmnvcp4jnop4/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_KriEt6WMV6 from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.4.1_@types+node@22.9.0_eslint@9.10.0_jiti@2.4.0__ioredis@5.4._ywb2t4re56kkldsmnvcp4jnop4/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import supabase_client_0Xg0t7ySbj from "/opt/render/project/src/node_modules/.pnpm/@nuxtjs+supabase@1.4.0/node_modules/@nuxtjs/supabase/dist/runtime/plugins/supabase.client.js";
import payload_client_WVXKbCyVNg from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.4.1_@types+node@22.9.0_eslint@9.10.0_jiti@2.4.0__ioredis@5.4._ywb2t4re56kkldsmnvcp4jnop4/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_mFw1sCLyRP from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.4.1_@types+node@22.9.0_eslint@9.10.0_jiti@2.4.0__ioredis@5.4._ywb2t4re56kkldsmnvcp4jnop4/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_CY5Fghuv3z from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.4.1_@types+node@22.9.0_eslint@9.10.0_jiti@2.4.0__ioredis@5.4._ywb2t4re56kkldsmnvcp4jnop4/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_H2PuSJPbWJ from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.4.1_@types+node@22.9.0_eslint@9.10.0_jiti@2.4.0__ioredis@5.4._ywb2t4re56kkldsmnvcp4jnop4/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_1239QMQ9Bq from "/opt/render/project/src/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.26.0_typescript@5.6.3_vue@3.5.12_typescript@5.6.3_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/opt/render/project/src/apps/ui/.nuxt/components.plugin.mjs";
import prefetch_client_RWm1kT7cyz from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.4.1_@types+node@22.9.0_eslint@9.10.0_jiti@2.4.0__ioredis@5.4._ywb2t4re56kkldsmnvcp4jnop4/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import preview_client_SzMQrTD6n3 from "/opt/render/project/src/node_modules/.pnpm/@nuxthq+studio@2.2.1_magicast@0.3.5_rollup@4.26.0/node_modules/@nuxthq/studio/dist/runtime/plugins/preview.client.js";
import plugin_bME2FUtqQq from "/opt/render/project/src/node_modules/.pnpm/pinia-plugin-persistedstate@4.1.3_@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.26.0_typescript@5_6omkfbtktwlxaxx27baqjn7hpy/node_modules/pinia-plugin-persistedstate/dist/nuxt/runtime/plugin.js";
import slideovers_KfourdWLlh from "/opt/render/project/src/node_modules/.pnpm/@nuxt+ui@2.18.6_axios@1.7.7_change-case@5.4.4_focus-trap@7.6.0_magicast@0.3.5_rollup@4.26.0_t_yy7pdiuxkijn3u6kfbvmm7si7m/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_6QOK6ZGZeN from "/opt/render/project/src/node_modules/.pnpm/@nuxt+ui@2.18.6_axios@1.7.7_change-case@5.4.4_focus-trap@7.6.0_magicast@0.3.5_rollup@4.26.0_t_yy7pdiuxkijn3u6kfbvmm7si7m/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_BRQvDlloce from "/opt/render/project/src/node_modules/.pnpm/@nuxt+ui@2.18.6_axios@1.7.7_change-case@5.4.4_focus-trap@7.6.0_magicast@0.3.5_rollup@4.26.0_t_yy7pdiuxkijn3u6kfbvmm7si7m/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_62M0TI8w5Y from "/opt/render/project/src/node_modules/.pnpm/@nuxtjs+color-mode@3.5.1_magicast@0.3.5_rollup@4.26.0/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_gl4ljHAEP0 from "/opt/render/project/src/node_modules/.pnpm/@nuxt+icon@1.5.1_magicast@0.3.5_rollup@4.26.0_vite@5.4.8_@types+node@22.9.0_sass-embedded@1.7_gcehd6m2kp2gepwb3w5enjkvkq/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import scrollbars_client_qTUVSySvNY from "/opt/render/project/src/node_modules/.pnpm/@nuxt+ui-pro@1.4.3_axios@1.7.7_change-case@5.4.4_focus-trap@7.6.0_magicast@0.3.5_rollup@4.26._mcd6ofcd6en6ejsougzrfom6eq/node_modules/@nuxt/ui-pro/modules/pro/runtime/plugins/scrollbars.client.ts";
import presets_AsWGeB6sUh from "/opt/render/project/src/node_modules/.pnpm/@nuxt+ui-pro@1.4.3_axios@1.7.7_change-case@5.4.4_focus-trap@7.6.0_magicast@0.3.5_rollup@4.26._mcd6ofcd6en6ejsougzrfom6eq/node_modules/@nuxt/ui-pro/plugins/presets.ts";
import variables_lBAEYSfzFl from "/opt/render/project/src/node_modules/.pnpm/@nuxt+ui-pro@1.4.3_axios@1.7.7_change-case@5.4.4_focus-trap@7.6.0_magicast@0.3.5_rollup@4.26._mcd6ofcd6en6ejsougzrfom6eq/node_modules/@nuxt/ui-pro/plugins/variables.ts";
import feature_flags_client_yDiIZxtHJu from "/opt/render/project/src/apps/ui/core/plugins/feature-flags.client.ts";
import lottie_YCAtSIlAiw from "/opt/render/project/src/apps/ui/core/plugins/lottie.ts";
import openapi_ueP8qSReR8 from "/opt/render/project/src/apps/ui/core/plugins/openapi.ts";
import sentry_client_4BqsDhRlQY from "/opt/render/project/src/apps/ui/core/plugins/sentry.client.ts";
import tracing_client_Phhn2yfSIG from "/opt/render/project/src/apps/ui/core/plugins/tracing.client.ts";
export default [
  unhead_CKEdhMrsU0,
  router_KriEt6WMV6,
  supabase_client_0Xg0t7ySbj,
  payload_client_WVXKbCyVNg,
  navigation_repaint_client_mFw1sCLyRP,
  check_outdated_build_client_CY5Fghuv3z,
  chunk_reload_client_H2PuSJPbWJ,
  plugin_vue3_1239QMQ9Bq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_RWm1kT7cyz,
  preview_client_SzMQrTD6n3,
  plugin_bME2FUtqQq,
  slideovers_KfourdWLlh,
  modals_6QOK6ZGZeN,
  colors_BRQvDlloce,
  plugin_client_62M0TI8w5Y,
  plugin_gl4ljHAEP0,
  scrollbars_client_qTUVSySvNY,
  presets_AsWGeB6sUh,
  variables_lBAEYSfzFl,
  feature_flags_client_yDiIZxtHJu,
  lottie_YCAtSIlAiw,
  openapi_ueP8qSReR8,
  sentry_client_4BqsDhRlQY,
  tracing_client_Phhn2yfSIG
]