<script setup lang="ts">
import OnboardingV2OptionSelector from './OnboardingV2OptionSelector.vue'

export interface Option {
  id: number
  label: string
  value: string
}

defineProps<{
  step: string
  options: Option[]
}>()

const emit = defineEmits<{
  optionClick: [option: Option, step: string]
}>()
</script>

<template>
  <div class="flex flex-col gap-4">
    <OnboardingV2OptionSelector
      v-for="option in options"
      :key="option.label"
      @option-click="emit('optionClick', option, step)"
    >
      {{ option.label }}
    </OnboardingV2OptionSelector>
  </div>
</template>
